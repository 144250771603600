<template>
  <div id="divRoot" class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <!-- begin::Page loader -->
    <div class="page-loader page-loader-logo" style="background-color: black;">
      <img alt="Logo" :src="assets.logoLight" width="100" />
      <div class="spinner"></div>
    </div>
    <!-- end::Page Loader -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->

        <!-- begin:: Content Body -->
        <div class="d-flex flex-column-fluid">
          <div class="container-fluid">
            <transition name="fade-in-up">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </div>
    <!-- begin::Scrolltop -->
    <div id="kt_scrolltop" ref="kt_scrolltop" class="scrolltop">
      <span class="svg-icon">
        <inline-svg src="/svg/scroll_top.svg" />
      </span>
    </div>
    <!-- end::Scrolltop -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module.js";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTLayoutScrolltop from "@/assets/js/layout/extended/scrolltop.js";

import logoLight from '@/assets/image/logo_light.png';

import { firebaseAuth, firestore } from '../../main';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile
  },
  computed: {
    ...mapGetters([
      "layoutConfig"
    ]),
    currentRouteName() {
      return this.$route.name;
    }
  },
  watch: {
    currentRouteName() {
      this.loadAllData();
    }
  },
  data() {
    return {
      assets: {
        logoLight
      },
      snapTransactionDailyList: null,
      snapDrinkDailyList: null,
      snapUserDailyList: null,
      snapStatsList: null,
      snapUserList: null,
      snapEventList: null,
      snapPostList: null,
      snapTransactionList: null,
      snapWithdrawList: null,
      snapReportList: null,
      snapNotificationList: null,
      snapPayPalWebHookList: null,
      snapTransactionTempList: null,
      snapDrinkOrderList: null,
      snapDrinkPayoutList: null,
      snapStoryList: null,
      snapAlbumList: null
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // Init Scrolltop
    KTLayoutScrolltop.init(this.$refs["kt_scrolltop"]);
    window.addEventListener('resize', this.onResize);
    firebaseAuth.onAuthStateChanged(user => {
      if (user && user.uid === this.ADMIN_UID) {
        this.loadAllData();
      } else {
        this.$router.push('/login').catch(()=>{});
      }
	  });
    setTimeout(() => {
      this.setIsMobile();
    }, 50);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
    if (this.snapTransactionDailyList) {
      this.snapTransactionDailyList();
    }
    if (this.snapDrinkDailyList) {
      this.snapDrinkDailyList();
    }
    if (this.snapUserDailyList) {
      this.snapUserDailyList();
    }
    if (this.snapStatsList) {
      this.snapStatsList();
    }
    if (this.snapUserList) {
      this.snapUserList();
    }
    if (this.snapEventList) {
      this.snapEventList();
    }
    if (this.snapPostList) {
      this.snapPostList();
    }
    if (this.snapTransactionList) {
      this.snapTransactionList();
    }
    if (this.snapWithdrawList) {
      this.snapWithdrawList();
    }
    if (this.snapReportList) {
      this.snapReportList();
    }
    if (this.snapNotificationList) {
      this.snapNotificationList();
    }
    if (this.snapPayPalWebHookList) {
      this.snapPayPalWebHookList();
    }
    if (this.snapTransactionTempList) {
      this.snapTransactionTempList();
    }
    if (this.snapDrinkOrderList) {
      this.snapDrinkOrderList();
    }
    if (this.snapDrinkPayoutList) {
      this.snapDrinkPayoutList();
    }
    if (this.snapStoryList) {
      this.snapStoryList();
    }
    if (this.snapAlbumList) {
      this.snapAlbumList();
    }
  },
  methods: {
    onResize() {
      this.setIsMobile();
    },
    setIsMobile() {
      const divRoot = document.getElementById('divRoot');
      if (divRoot) {
        const clientWidth = divRoot.clientWidth;
        const isMobile = this.$isMobile() === true || clientWidth < 560;
        var colSmSize = 1;
        if (clientWidth < 800) {
          colSmSize = '-12';
        } else if (clientWidth < 1400) {
          colSmSize = '-6';
        } else if (clientWidth < 1800) {
          colSmSize = '-4';
        } else {
          colSmSize = '-3';
        }
        this.$store.commit('set', ['isMobile', isMobile]);
        this.$store.commit('set', ['colSmSize', colSmSize]);
      }
    },
    loadAllData() {
      if (this.currentRouteName === 'Dashboard') {
        this.getStatsListTask();
        this.getTransactionDailyListTask();
        this.getDrinkDailyListTask();
        this.getUserDailyListTask();
      }
      if (this.currentRouteName === 'ReportList') {
        this.getReportListTask();
      }
      if (this.currentRouteName === 'WithdrawListByUserId' || this.currentRouteName === 'WithdrawListByEventId') {
        this.getWithdrawListTask();
      }
      if (this.currentRouteName === 'SendMessage') {
        this.getNotificationListTask();
      }
      if (this.currentRouteName === 'PostList' || this.currentRouteName === 'ReportList') {
        this.getPostListTask();
      }
      if (this.currentRouteName === 'StoryList') {
        this.getStoryListTask();
      }
      if (this.currentRouteName === 'AlbumList' || this.currentRouteName === 'AlbumDetail') {
        this.getAlbumListTask();
      }
      if (this.currentRouteName === 'TransactionListByUserId' ||
          this.currentRouteName === 'TransactionListByEventId' ||
          this.currentRouteName === 'RefundListByEventId' ||
          this.currentRouteName === 'WithdrawListByUserId' ||
          this.currentRouteName === 'WithdrawListByEventId') {
        this.getTransactionListTask();
      }
      if (this.currentRouteName === 'EventList' ||
          this.currentRouteName === 'TransactionListByUserId' ||
          this.currentRouteName === 'TransactionListByEventId' ||
          this.currentRouteName === 'RefundListByEventId' ||
          this.currentRouteName === 'WithdrawListByUserId' ||
          this.currentRouteName === 'WithdrawListByEventId' ||
          this.currentRouteName === 'TicketList' ||
          this.currentRouteName === 'ReportList' ||
          this.currentRouteName === 'DrinkList' ||
          this.currentRouteName === 'DrinkOrderList' ||
          this.currentRouteName === 'DrinkPayoutList' ||
          this.currentRouteName === 'SetTicketMinPrice' ||
          this.currentRouteName === 'SendEmail') {
        this.getEventListTask();
      }
      if (this.currentRouteName === 'UserList' ||
          this.currentRouteName === 'UserDetail' ||
          this.currentRouteName === 'EventList' ||
          this.currentRouteName === 'PostList' ||
          this.currentRouteName === 'ReportList' ||
          this.currentRouteName === 'TransactionListByUserId' ||
          this.currentRouteName === 'TransactionListByEventId' ||
          this.currentRouteName === 'RefundListByEventId' ||
          this.currentRouteName === 'WithdrawListByUserId' ||
          this.currentRouteName === 'WithdrawListByEventId' ||
          this.currentRouteName === 'TransactionTemp'||
          this.currentRouteName === 'DrinkList' ||
          this.currentRouteName === 'DrinkOrderList' ||
          this.currentRouteName === 'DrinkPayoutList' ||
          this.currentRouteName === 'DrinkListByUserId' ||
          this.currentRouteName === 'DrinkOrderListByUserId' ||
          this.currentRouteName === 'DrinkPayoutListByUserId' ||
          this.currentRouteName === 'StoryList' ||
          this.currentRouteName === 'AlbumList' ||
          this.currentRouteName === 'SendEmail') {
        this.getUserListTask();
      }
      if (this.currentRouteName === 'DrinkOrderList' ||
          this.currentRouteName === 'DrinkPayoutList' ||
          this.currentRouteName === 'DrinkOrderListByUserId' ||
          this.currentRouteName === 'DrinkPayoutListByUserId') {
        this.getDrinkOrderListTask();
      }
      if (this.currentRouteName === 'DrinkPayoutList' || this.currentRouteName === 'DrinkPayoutListByUserId') {
        this.getDrinkPayoutListTask();
      }
      if (this.currentRouteName === 'PayPalWebHook' || this.currentRouteName === 'TransactionTemp') {
        this.getTransactionTempListTask();
        this.getPayPalWebHookListTask();
      }
      if (this.currentRouteName === 'VIPTicketLink') {
        this.getTransactionTempListTask();
      }
    },
    getNotificationListTask() {
      if (this.snapNotificationList) {
        return;
      }
      const q = query(collection(firestore, 'notification'), where('receiverId', '==', 'common'));
      this.snapNotificationList = onSnapshot(q, querySnapshot => {
        const notificationList = [];
        querySnapshot.forEach(doc => notificationList.push(doc.data()));
        notificationList.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.$store.commit('set', ['notificationList', notificationList]);
      });
    },
    getTransactionListTask() {
      if (this.snapTransactionList) {
        return;
      }
      const q = query(collection(firestore, 'transaction'), where('totalPrice', '>', 0));
      this.snapTransactionList = onSnapshot(q, querySnapshot => {
        const transactionList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (window.location.hostname === 'localhost' || !data.eventId.startsWith(this.TEST_PREFIX)) {
            transactionList.push(data);
          }
        });
        this.$store.commit('set', ['transactionList', transactionList]);
        /*var handlingFee = 0;
        var paid = 0;
        var promoDiscount = 0;
        var refundFee = 0;
        var refundPaidAmount = 0;
        var refundPaidServiceCharge = 0;
        var refundRequestedAmount = 0;
        var refundRequestedServiceCharge = 0;
        var refunds = 0;
        var rewards = 0;
        var salesAmount = 0;
        var serviceCharge = 0;
        var ticketPrice = 0;
        var totalPrice = 0;
        var transactions = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.rewardId && data.eventId && !data.eventId.startsWith(this.TEST_PREFIX)) {
            rewards++;
          }
          if (!data.transactionId.startsWith('PayPal-Sandbox') && !data.transactionId.startsWith('Stripe-Test')) {
            salesAmount += data.salesAmount || 0;
            handlingFee += data.handlingFee;
            if (data.totalPrice > 0) {
              paid++;
            }
            if (data.promoDiscount) {
              promoDiscount += data.promoDiscount;
            }
            if (data.refundPaidAt) {
              refundPaidAmount += data.totalPrice;
              refundPaidServiceCharge += data.serviceCharge;
            }
            if (data.refundRequestedAt) {
              refundRequestedAmount += data.totalPrice;
              refundRequestedServiceCharge += data.serviceCharge;
              refundFee += data.refundFee;
              refunds++;
            }
            serviceCharge += data.serviceCharge;
            ticketPrice += data.ticketPrice;
            totalPrice += data.totalPrice;
            transactions++;
          }
        });
        console.log(`handlingFee - ${handlingFee}`);
        console.log(`paid - ${paid}`);
        console.log(`promoDiscount - ${promoDiscount}`);
        console.log(`refundFee - ${refundFee}`);
        console.log(`refundPaidAmount - ${refundPaidAmount}`);
        console.log(`refundPaidServiceCharge - ${refundPaidServiceCharge}`);
        console.log(`refundRequestedAmount - ${refundRequestedAmount}`);
        console.log(`refundRequestedServiceCharge - ${refundRequestedServiceCharge}`);
        console.log(`refunds - ${refunds}`);
        console.log(`rewards - ${rewards}`);
        console.log(`salesAmount - ${salesAmount}`);
        console.log(`serviceCharge - ${serviceCharge}`);
        console.log(`ticketPrice - ${ticketPrice}`);
        console.log(`totalPrice - ${totalPrice}`);
        console.log(`transactions - ${transactions}`);*/
      });
    },
    getWithdrawListTask() {
      if (this.snapWithdrawList) {
        return;
      }
      this.snapWithdrawList = onSnapshot(collection(firestore, 'withdraw'), querySnapshot => {
        const withdrawList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (window.location.hostname === 'localhost' || !data.eventId.startsWith(this.TEST_PREFIX)) {
            withdrawList.push(data);
          }
        });
        this.$store.commit('set', ['withdrawList', withdrawList]);
      });
    },
    getReportListTask() {
      if (this.snapReportList) {
        return;
      }
      this.snapReportList = onSnapshot(collection(firestore, 'report'), querySnapshot => {
        const reportList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          data['reportId'] = doc.id;
          reportList.push(data);
        });
        this.$store.commit('set', ['reportList', reportList]);
      });
    },
    getPostListTask() {
      if (this.snapPostList) {
        return;
      }
      this.snapPostList = onSnapshot(collection(firestore, 'post'), querySnapshot => {
        const postList = [];
        querySnapshot.forEach(doc => {
          if (window.location.hostname === 'localhost' || !doc.id.startsWith(this.TEST_PREFIX)) {
            postList.push(doc.data());
          }
        });
        this.$store.commit('set', ['postList', postList]);
      });
    },
    getEventListTask() {
      if (this.snapEventList) {
        return;
      }
      this.snapEventList = onSnapshot(collection(firestore, 'event'), querySnapshot => {
        const eventList = [];
        querySnapshot.forEach(doc => {
          if (window.location.hostname === 'localhost' || !doc.id.startsWith(this.TEST_PREFIX)) {
            eventList.push(doc.data());
          }
        });
        this.$store.commit('set', ['eventList', eventList]);
      });
    },
    getUserListTask() {
      if (this.snapUserList) {
        return;
      }
      //const q = query(collection(firestore, 'user'), where('userType', '==', 1));
      //this.snapUserList = onSnapshot(q, querySnapshot => {
      this.snapUserList = onSnapshot(collection(firestore, 'user'), querySnapshot => {
        const userList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          const deviceType = data.deviceType || '';
          if (window.location.hostname === 'localhost') {
            data['deviceType'] = deviceType;
            if (deviceType.startsWith('Android : ')) {
              data['deviceTypeColor'] = 'color-red';
            } else if (deviceType.startsWith('iOS : ')) {
              data['deviceTypeColor'] = 'color-blue';
            } else if (deviceType.startsWith('Web : ')) {
              data['deviceTypeColor'] = 'color-purple';
            }
          } else {
            const myArray = deviceType.split(' : ');
            if (myArray && myArray.length > 0) {
              data['deviceType'] = myArray[0];
            } else {
              data['deviceType'] = '';
            }
            data['deviceTypeColor'] = 'color-primary';
          }
          userList.push(data);
        });
        this.$store.commit('set', ['userList', userList]);
      });
    },
    getTransactionDailyListTask() {
      if (this.snapTransactionDailyList) {
        return;
      }
      this.snapTransactionDailyList = onSnapshot(collection(firestore, 'stats', 'transaction', 'daily'), querySnapshot => {
        const transactionDailyList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          data['date'] = doc.id;
          transactionDailyList.push(data);
        });
        this.$store.commit('set', ['transactionDailyList', transactionDailyList]);
      });
    },
    getDrinkDailyListTask() {
      if (this.snapDrinkDailyList) {
        return;
      }
      this.snapDrinkDailyList = onSnapshot(collection(firestore, 'stats', 'drink', 'daily'), querySnapshot => {
        const drinkDailyList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          data['date'] = doc.id;
          drinkDailyList.push(data);
        });
        this.$store.commit('set', ['drinkDailyList', drinkDailyList]);
      });
    },
    getUserDailyListTask() {
      if (this.snapUserDailyList) {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        return;
      }
      this.snapUserDailyList = onSnapshot(collection(firestore, 'stats', 'user', 'daily'), querySnapshot => {
        const userDailyList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          data['date'] = doc.id;
          userDailyList.push(data);
        });
        this.$store.commit('set', ['userDailyList', userDailyList]);
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      });
    },
    getStatsListTask() {
      if (this.snapStatsList) {
        return;
      }
      this.snapStatsList = onSnapshot(collection(firestore, 'stats'), querySnapshot => {
        const statsMap = {};
        querySnapshot.forEach(doc => {
          const data = doc.data();
          statsMap[doc.id] = data;
        });
        this.$store.commit('set', ['statsMap', statsMap]);
      });
    },
    getPayPalWebHookListTask() {
      if (this.snapPayPalWebHookList) {
        return;
      }
      this.snapPayPalWebHookList = onSnapshot(collection(firestore, 'paypalWebhook'), querySnapshot => {
        const paypalWebHookList = [];
        querySnapshot.forEach(doc => paypalWebHookList.push(doc.data()));
        paypalWebHookList.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.$store.commit('set', ['paypalWebHookList', paypalWebHookList]);
      });
    },
    getTransactionTempListTask() {
      if (this.snapTransactionTempList) {
        return;
      }
      this.snapTransactionTempList = onSnapshot(collection(firestore, 'transactionTemp'), querySnapshot => {
        const transactionTempList = [];
        querySnapshot.forEach(doc => transactionTempList.push(doc.data()));
        transactionTempList.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.$store.commit('set', ['transactionTempList', transactionTempList]);
      });
    },
    getDrinkOrderListTask() {
      if (this.snapDrinkOrderList) {
        return;
      }
      const q = query(collection(firestore, 'drinkOrder'), where('paymentMethod', '!=', ''));
      this.snapDrinkOrderList = onSnapshot(q, querySnapshot => {
        const drinkOrderList = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (window.location.hostname === 'localhost' || !data.eventId.startsWith(this.TEST_PREFIX)) {
            drinkOrderList.push(data);
          }
        });
        this.$store.commit('set', ['drinkOrderList', drinkOrderList]);
        /*var drinkPrice = 0;
        var handlingFee = 0;
        var orders = 0;
        var ordersCard = 0;
        var ordersCash = 0;
        var tipPrice = 0;
        var totalPrice = 0;
        var totalPriceCard = 0;
        var totalPriceCash = 0;
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (!data.eventId.startsWith(this.TEST_PREFIX)) {
            orders++;
            drinkPrice += data.drinkPrice;
            tipPrice += data.tipPrice;
            totalPrice += data.totalPrice;
            handlingFee += data.handlingFee;
            if (data.paymentMethod === this.PAYMENT_TYPE_CARD) {
              ordersCard++;
              totalPriceCard += data.totalPrice;
            } else if (data.paymentMethod === this.PAYMENT_TYPE_CASH) {
              ordersCash++;
              totalPriceCash += data.totalPrice;
            }
          }
        });
        console.log(`drinkPrice - ${drinkPrice}`);
        console.log(`handlingFee - ${handlingFee}`);
        console.log(`orders - ${orders}`);
        console.log(`ordersCard - ${ordersCard}`);
        console.log(`ordersCash - ${ordersCash}`);
        console.log(`tipPrice - ${tipPrice}`);
        console.log(`totalPrice - ${totalPrice}`);
        console.log(`totalPriceCard - ${totalPriceCard}`);
        console.log(`totalPriceCash - ${totalPriceCash}`);*/
      });
    },
    getDrinkPayoutListTask() {
      if (this.snapDrinkPayoutList) {
        return;
      }
      this.snapDrinkPayoutList = onSnapshot(collection(firestore, 'drinkPayout'), querySnapshot => {
        const drinkPayoutList = [];
        querySnapshot.forEach(doc => {
          if (window.location.hostname === 'localhost' || !doc.id.startsWith(this.TEST_PREFIX)) {
            const info = doc.data();
            info['payoutId'] = doc.id;
            drinkPayoutList.push(info);
          }
        });
        this.$store.commit('set', ['drinkPayoutList', drinkPayoutList]);
      });
    },
    getStoryListTask() {
      if (this.snapStoryList) {
        return;
      }
      this.snapStoryList = onSnapshot(collection(firestore, 'story'), querySnapshot => {
        const storyList = [];
        querySnapshot.forEach(doc => {
          if (window.location.hostname === 'localhost' || !doc.id.startsWith(this.TEST_PREFIX)) {
            storyList.push(doc.data());
          }
        });
        this.$store.commit('set', ['storyList', storyList]);
      });
    },
    getAlbumListTask() {
      if (this.snapAlbumList) {
        return;
      }
      this.snapAlbumList = onSnapshot(collection(firestore, 'album'), querySnapshot => {
        const albumList = [];
        querySnapshot.forEach(doc => {
          if (window.location.hostname === 'localhost' || !doc.id.startsWith(this.TEST_PREFIX)) {
            albumList.push(doc.data());
          }
        });
        this.$store.commit('set', ['albumList', albumList]);
      });
    }
  }
};
</script>
